<template>
  <tippy
    ref="tippyRef"
    class="popover"
    :trigger="!props.isHover ? 'click' : 'mouseenter click'"
    interactive
    :hide-on-click="!isMobile && !props.ignoreCloseOnDesktop"
    :placement="props.position"
    :append-to="body"
    :max-width="props.maxWidth"
    :content-class="className"
    :tag="tag"
    :z-index="props.zIndex"
    :touch="props.touch"
    @show="show"
    @after-update="showIfOpen"
    @hide="emit('close')"
  >
    <slot name="activator" />

    <template #content="{ hide, state }">
      <slot v-if="state.isMounted || state.isShown" name="content" :hide="hide" />
      <AIconButton
        v-show="withClose"
        icon-name="close-grey"
        class="popover-element__close"
        @click.stop="hide()"
      />
    </template>
  </tippy>
  <Teleport
    v-if="isOpen"
    to="body"
  >
    <transition name="popover-overlay">
      <AOverlay
        v-show="isPopoverDisplayed"
        ref="overlayComponent"
        class="popover-overlay"
        @click.self.stop="close"
      />
    </transition>

    <div
      class="popover-element"
      :class="{
        'popover-element_show': isPopoverDisplayed,
        [className ?? '']: className,
      }"
    >
      <div
        ref="swipeElement"
        style="width: 100%;"
        class="popover-element__body"
        @touchstart.stop.passive="moveElementStart($event)"
        @touchmove.stop.passive="moveElement($event)"
        @touchend.stop.passive="moveElementEnd($event)"
      >
        <div class="content innerElementScroll">
          <slot name="content" :hide="close" />
        </div>
        <div class="b-button-dash" />
        <AIconButton
          v-show="withClose || isMobile"
          icon-name="close-grey"
          class="popover-element__close"
          @click.stop="close"
        />
      </div>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import {
  defineComponent,
  ref,
  nextTick,
  onMounted,
  onBeforeUnmount,
  watch
} from 'vue'
import { Tippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css'

import type { PropType } from 'vue'
import type { PopoverPosition } from '@/components/molecules/Popover/types'

import { useSwipeModal } from '@/utils/use-swipe-modal'
import { useBodyScroll } from '@/composables/body-scroll'
import { useMediaQuery } from '@/composables/media-query'

import AIconButton from '@/components/atoms/IconButton/AIconButton.vue'
import AOverlay from '@/components/atoms/Overlay/AOverlay.vue'

defineComponent({ name: 'MPopover' })
defineSlots<{
  activator?: (props: { msg?: string }) => any
  content?: (props: { hide?: any }) => any
}>()
const { enableScroll, disableScroll } = useBodyScroll()
const props = defineProps({
  position: {
    type: String as PropType<PopoverPosition>,
    default: undefined
  },
  withClose: {
    type: Boolean,
    default: false
  },
  className: {
    type: String,
    default: undefined
  },
  isHover: {
    type: Boolean,
    default: false
  },
  maxWidth: {
    type: [String, Number],
    default: 500
  },
  touch: {
    type: Boolean,
    default: true
  },
  tag: {
    type: String,
    default: 'div'
  },
  open: {
    type: Boolean,
    default: false
  },
  disable: {
    type: Boolean,
    default: false
  },
  innerElementScrollClass: {
    type: String,
    default: undefined
  },
  zIndex: {
    type: Number,
    default: 1000
  },
  ignoreScroll: Boolean,
  ignoreCloseOnDesktop: Boolean
})

const emit = defineEmits(['open', 'close', 'update:modelValue'])
const tippyRef = ref<any>()
let timeout: ReturnType<typeof setTimeout> | null = null

const isPopoverDisplayed = ref(false)
const isOpen = ref(false)
const body = ref<HTMLElement>()
const isClosing = ref(false)

const { isMobile } = useMediaQuery(true, () => {
  if (tippyRef.value && props.open) {
    if (isMobile.value) {
      show()
    } else {
      isOpen.value = false
      enableScroll()
    }
  }
})

const onScrollWindow = () => {
  if (!isMobile.value && tippyRef.value) {
    tippyRef.value.hide()
  }
}

const addListeners = (): void => {
  if (!isMobile.value && props.ignoreCloseOnDesktop) { return }
  window.addEventListener('scroll', onScrollWindow, { passive: true })
}

const removeListeners = (): void => {
  if (!isMobile.value && props.ignoreCloseOnDesktop) { return }
  window.removeEventListener('scroll', onScrollWindow)
}
onMounted(() => {
  body.value = document.body
  if (props.disable && tippyRef.value) {
    tippyRef.value.disable()
  }

  addListeners()
})

onBeforeUnmount(() => {
  removeListeners()
  removeFixes()
  if (timeout) { clearTimeout(timeout) }
})

const removeFixes = () => {
  if (!isMobile.value) { return }

  if (
    !body.value?.classList.contains('.modal-open') &&
    !props.ignoreScroll &&
    (isClosing.value || isOpen.value)
  ) {
    enableScroll()
  }
}

const showIfOpen = (): void => {
  if (props.open && tippyRef.value) {
    tippyRef.value.show()
  }
}
const show = (): void => {
  if (isMobile.value) {
    if (!isClosing.value) {
      if (isPopoverDisplayed.value) {
        return
      }

      isOpen.value = true

      disableScroll()

      timeout = setTimeout(async () => {
        emit('open')
        isPopoverDisplayed.value = true
      }, 0)
    }
  } else {
    emit('open')
  }
}
const close = (): void => {
  if (isMobile.value) {
    if (!isClosing.value) {
      isClosing.value = true
      const element = document.querySelector('.popover-element__body')
      if (element) {
        element.removeAttribute('style')
      }

      isPopoverDisplayed.value = false
      timeout = setTimeout(() => {
        isOpen.value = false
        isClosing.value = false

        if (tippyRef.value) {
          tippyRef.value.hide()
          tippyRef.value.unmount()
        }
      }, 100)

      removeFixes()
    }
  }
}
watch(() => props.disable, (v) => {
  if (tippyRef.value) {
    if (v) {
      tippyRef.value.disable()
    } else {
      tippyRef.value.enable()
    }
  }
})
watch(() => props.open, async (v) => {
  if (tippyRef.value) {
    if (v) {
      await nextTick()
      tippyRef.value.show()
    } else {
      tippyRef.value.hide()
      close()
    }
  }
})

const {
  swipeElement,
  overlayComponent,
  moveElementStart,
  moveElement,
  moveElementEnd
} = useSwipeModal({
  isActive: true,
  innerElementScrollClass: props.innerElementScrollClass ?? 'innerElementScroll',
  close
})

</script>

<style lang="postcss">
.popover-overlay {
  position: fixed;
  z-index: 1001;
  overflow: hidden;
  height: 100%;

  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}

.popover-element {
  --popover-pb: var(--spacer-base);

  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  justify-content: start;
  width: 100vw;
  box-shadow: var(--shadow-base-double), var(--shadow-base);
  transform: translateY(0);

  &-enter-active,
  &-leave-active {
    transition: transform 0.2s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: translateY(50px);
  }

  &_show {
    .popover-element__body {
      transform: translateY(0);
    }
  }

  .content {
    position: relative;
    z-index: 10;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    max-height: 90vh;
    padding-top: var(--spacer-base);
    padding-right: var(--spacer-3xs);
    padding-left: var(--spacer-3xs);
    pointer-events: auto;
  }

  &__body {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: var(--z-modal);
    overflow: hidden;
    width: 100%;
    padding-bottom: calc(
      env(safe-area-inset-bottom) + var(--popover-pb)
    ) !important;
    border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
    background: var(--color-white);
    transition: transform 0.2s ease-in-out;
    transform: translateY(100%);
    will-change: transform;
  }

  &__close {
    position: absolute;
    top: var(--spacer-4xs);
    right: var(--spacer-4xs);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: var(--spacer-4xs);
    color: var(--color-neutral-500);
    pointer-events: auto;

    &::before {
      width: 16px;
      height: 16px;
    }
  }

  @media (--screen-lg) {
    pointer-events: none;
  }

}

@media (--screen-xs) {
  .popover-element {
    &__close {
      width: auto;
      height: auto;
      padding: var(--spacer-2xs);

      &::before {
        width: var(--spacer-base);
        height: var(--spacer-base);
      }
    }
  }
}

.tippy-box {
  position: relative;
  padding: 0;
  border-radius: var(--border-radius-xs);
  background-color: transparent !important;
  box-shadow: var(--shadow-base-double), var(--shadow-base);
}

.tippy-box .tippy-content {
  padding: var( --spacer-xs) var(--spacer-3xs);
  border-radius: var(--border-radius-xs);
  background-color: var(--color-white);
}

.tippy-box .tippy-arrow {
  color: var(--color-white)
}

.tippy-box .tippy-content .list-item {
  padding: var(--spacer-3xs) var(--spacer-xs);
}

.tippy-box .tippy-content .list-item__title {
  white-space: nowrap;

  @mixin text-base;
}

@media (--screen-xs) {
  [data-tippy-root] {
    display: none;
  }
}
</style>
