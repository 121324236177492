import absent from '@/settings/absent.json'
import accessory from '@/settings/accessory.json'
import aperitive from '@/settings/aperitive.json'
import armagnac from '@/settings/armagnac.json'
import beer from '@/settings/beer.json'
import brandy from '@/settings/brandy.json'
import cachaca from '@/settings/cachaca.json'
import calvados from '@/settings/calvados.json'
import caviar from '@/settings/caviar.json'
import champagnes from '@/settings/champagnes.json'
import cheese from '@/settings/cheese.json'
import chocolate from '@/settings/chocolate.json'
import cider from '@/settings/cider.json'
import cognac from '@/settings/cognac.json'
import foieGras from '@/settings/foie-gras.json'
import giftPack from '@/settings/gift-pack.json'
import gin from '@/settings/gin.json'
import glass from '@/settings/glass.json'
import grappa from '@/settings/grappa.json'
import grocery from '@/settings/grocery.json'
import juice from '@/settings/juice.json'
import liqueur from '@/settings/liqueur.json'
import oil from '@/settings/oil.json'
import rum from '@/settings/rum.json'
import sake from '@/settings/sake.json'
import schnaps from '@/settings/schnaps.json'
import syrup from '@/settings/syrup.json'
import tequila from '@/settings/tequila.json'
import truffles from '@/settings/truffles.json'
import vinegar from '@/settings/vinegar.json'
import vodka from '@/settings/vodka.json'
import water from '@/settings/water.json'
import whisky from '@/settings/whisky.json'
import wine from '@/settings/wine.json'

type CharacteristicInfo = { accent: object[][] }

export type ProductConfig = {
  list: CharacteristicInfo
  grid: CharacteristicInfo
  page: CharacteristicInfo
  regionItemsNum: number
}

export const getConfigJSON = (category: string): ProductConfig | undefined => {
  switch (category) {
    case 'absent':
      return absent
    case 'accessory':
      return accessory
    case 'aperitive':
      return aperitive
    case 'armagnac':
      return armagnac
    case 'beer':
      return beer
    case 'brandy':
      return brandy
    case 'cachaca':
      return cachaca
    case 'calvados':
      return calvados
    case 'caviar':
      return caviar
    case 'champagnes':
      return champagnes
    case 'cheese':
      return cheese
    case 'chocolate':
      return chocolate
    case 'cider':
      return cider
    case 'cognac':
      return cognac
    case 'foie-gras':
      return foieGras
    case 'gift-pack':
      return giftPack
    case 'gin':
      return gin
    case 'glass':
      return glass
    case 'grappa':
      return grappa
    case 'grocery':
      return grocery
    case 'juice':
      return juice
    case 'liqueur':
      return liqueur
    case 'oil':
      return oil
    case 'rum':
      return rum
    case 'sake':
      return sake
    case 'schnaps':
      return schnaps
    case 'syrup':
      return syrup
    case 'tequila':
      return tequila
    case 'truffles':
      return truffles
    case 'vinegar':
      return vinegar
    case 'vodka':
      return vodka
    case 'water':
      return water
    case 'whisky':
      return whisky
    case 'wine':
      return wine
    default:
      return undefined
  }
}
