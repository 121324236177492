<template>
  <button
    class="button"
    :class="[
      {
        [`button--${variant}`]: variant,
        [`button--${size}`]: size,
        [`i-${icon}`]: iconPosition === 'left' || iconPosition === 'both',
      }
    ]"
    :aria-label="ariaLabel"
    :disabled="disabled"
    :title="title"
    @click="emitClick"
  >
    <slot />
    <i
      v-if="iconPosition === 'right' || iconPosition === 'both'"
      :class="`i-${icon}`"
    />
    <LazyWsLoader v-if="loading" />
  </button>
</template>

<script lang="ts" setup>
import './button.css'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { IconName } from '@/utils/icon-types'

const LazyWsLoader = defineAsyncComponent(() => /* @vite-ignore */ import('@/components/ui-kit/a-loader/Loader.vue'))

export type ButtonVariant = 'grey' | 'secondary' | 'outline' | 'text' | 'success'

defineComponent({ name: 'WsButton', inheritAttrs: false })

defineProps({
  title: { type: String, default: '' },
  icon: { type: String as PropType<IconName>, default: '' },
  iconPosition: {
    type: String as PropType<'left' | 'right' | 'both'>,
    default: ''
  },
  disabled: Boolean,
  size: {
    type: String as PropType<'xs' | 'sm' | 'lg' | 'xl' | undefined>,
    default: undefined
  },
  variant: {
    type: String as PropType<ButtonVariant>,
    default: undefined
  },
  ariaLabel: {
    type: String,
    required: false,
    default: undefined
  },
  loading: Boolean
})

const emit = defineEmits({
  click: (event: MouseEvent) => event instanceof MouseEvent
})

const emitClick = (event: MouseEvent): void => {
  emit('click', event)
}
</script>
