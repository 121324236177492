import { isString } from '@vue/shared'

export const isEmptyString = (str: string | any): str is string => isString(str) && str === ''

export const isNotEmptyString = (str: string | any): str is string => isString(str) && str !== ''

export const capitalize = (text: string): string => {
  const current = text.trim()
  return current !== ''
    ? current[0].toUpperCase() + current.slice(1).toLowerCase()
    : ''
}

/**
 * Получить склоненное слово в зависимости от переданного числа
 *
 * @param num число
 * @param titles массив форм слова, например ['объект', 'объекта', 'объектов']
 */
export const declOfNum = (num: number, titles: string[]): string => {
  const cases = [2, 0, 1, 1, 1, 2]

  if (num % 100 > 4 && num % 100 < 20) {
    return titles[2]
  }

  const dec = num % 10

  return dec < 5
    ? titles[cases[dec]]
    : titles[cases[5]]
}
