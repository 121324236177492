import { ref } from 'vue'
import { MIN_LAPTOP_WIDTH } from '@/composables/device'

export const useMediaQuery = (immediate = true, cb?: (event?: any) => void) => {
  const mediaQuery = '(max-width: 1023px)'
  const mediaQueryList = ref()

  const isMobile = ref(false)

  const mediaQueryCallback = (event?: any): void => {
    isMobile.value = window.innerWidth < MIN_LAPTOP_WIDTH

    cb?.(event)
  }

  onMounted(() => {
    mediaQueryList.value = window.matchMedia(mediaQuery)

    if (immediate) {
      mediaQueryCallback({
        matches: mediaQueryList.value.matches,
        media: mediaQuery
      } as MediaQueryListEvent)
    }

    if (mediaQueryList.value.addEventListener) {
      mediaQueryList.value.addEventListener('change', mediaQueryCallback)
    } else {
      mediaQueryList.value.addListener(mediaQueryCallback) // safari < 14 fix
    }
  })

  onBeforeUnmount(() => {
    if (mediaQueryList.value) {
      if (mediaQueryList.value.removeEventListener) {
        mediaQueryList.value.removeEventListener('change', mediaQueryCallback)
      } else {
        mediaQueryList.value.removeListener(mediaQueryCallback) // safari < 14 fix
      }
      mediaQueryList.value = null
    }
  })

  return {
    isMobile,
    mediaQueryList
  }
}
