export type ImageSizes = {
  mobile?: { w: string, h: string }
  desktop: { w: string, h: string }
}

export function getUrlWithTrailingSlashes (url: string, endWithSlash = true): string {
  return `/${url}${endWithSlash ? '/' : ''}`.replace(/\/\//g, '/')
}

export function getImageUrlWithSize (url: string | undefined, sizes: ImageSizes, isMobile = false): string {
  if (!url) {
    return ''
  }

  if (isMobile && sizes.mobile) {
    return url
      .replace('%w', sizes.mobile.w)
      .replace('%h', sizes.mobile.h)
      .replace('%t', '_mob')
  }

  return url
    .replace('%w', sizes.desktop.w)
    .replace('%h', sizes.desktop.h)
    .replace('%t', '')
}
