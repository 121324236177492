<template>
  <div
    ref="carousel"
    class="carousel"
    :class="{
      'carousel--full-width': isFullWidth,
      'carousel--dots-out': isFullWidth && dots === 'out',
      'carousel--equal': count,
    }"
  >
    <div
      @scroll.passive="e => totalLoadedItems !== null ? $emit('scroll', e) : null"
      @pointerdown="onPointerDown"
      @pointermove="onPointerMove"
      @pointercancel="onPointerEnd"
      @pointerleave="onPointerEnd"
      @pointerup="onPointerEnd"
    >
      <div
        ref="inner"
        :style="innerStyles"
        class="carousel__list"
        @touchstart.passive="onTouchStart"
        @touchmove.passive="onTouchMove"
        @touchend.passive="onTouchEnd"
      >
        <slot />
      </div>
    </div>

    <ClientOnly>
      <AIconButton
        v-custom-show="isAllowedLeft"
        :size="arrowSize"
        icon-name="chevron-right"
        variant="rounded"
        @click="slideTo(currentSlide - 1)"
      />
      <div v-custom-show="isAllowedLeft && withGradient" class="gradient gradient-left" />
      <AIconButton
        v-custom-show="isAllowedRight"
        :size="arrowSize"
        icon-name="chevron-right"
        variant="rounded"
        @click="slideTo(currentSlide + 1)"
      />
      <div v-custom-show="isAllowedRight && withGradient" class="gradient gradient-right" />
      <ACarouselDot
        v-if="isFullWidth && groupsCount > 1"
        :count="groupsCount"
        :current="currentSlide"
        @update:current="slideTo"
      />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch
} from 'vue'
import { useResizeObserver } from '@vueuse/core'
import { breakpoints, useDevice } from '@/composables/device'

import type { PropType } from 'vue'
import type { UseResizeObserverReturn } from '@vueuse/core'
import type { Breakpoints } from '@/composables/device'
import type { IconSize } from '@/utils/icon-types-static'

import AIconButton from '@/components/atoms/IconButton/AIconButton.vue'
import ACarouselDot from '@/components/atoms/CarouselDot/ACarouselDot.vue'

defineComponent({ name: 'MCarousel' })

const props = defineProps({
  loop: {
    type: Boolean,
    default: false
  },
  transition: {
    type: Number,
    default: 500
  },
  arrowSize: {
    type: String as PropType<IconSize>,
    default: 'lg'
  },
  itemsPerSlide: {
    type: Number,
    default: 0
  },
  breakPoints: {
    type: Object as PropType<Partial<Record<Breakpoints, number>>>,
    default: null
  },
  dots: {
    type: String as PropType<'in' | 'out' | undefined>,
    default: undefined
  },
  idxToSlide: {
    type: Number,
    default: 0
  },
  paddingOnScroll: {
    type: [String, Number],
    default: 0
  },
  totalLoadedItems: {
    type: Number,
    default: null
  },
  withGradient: {
    type: Boolean,
    default: false
  },
  withKeydown: Boolean
})

const emit = defineEmits(['change:slide', 'scroll'])

const screen = useDevice()
const carousel = ref<HTMLElement>()
const inner = ref<HTMLElement>()
const innerStyles = ref({})
const currentSlide = ref(0)
const count = ref(0)
const groupsCount = ref(0)
let innerGap = 0
let offsetWidth = 0
let maxShift = 0

let isFirstOpen = !!props.idxToSlide

let transitionTimer: ReturnType<typeof setTimeout> | null
let recalculateTimer: ReturnType<typeof setTimeout> | null
let timeout: ReturnType<typeof setTimeout> | null

const isFullWidth = computed(
  () =>
    (count.value === 1 && !!props.dots?.length) || props.itemsPerSlide === 1
)

const setCountPerPage = (): void => {
  if (props.breakPoints) {
    const activeScreen = breakpoints.filter(el => screen[el].value)[0]

    if (activeScreen) {
      count.value = props.breakPoints[activeScreen] ?? 1
      return
    }
  }
  count.value = props.itemsPerSlide > 0 ? props.itemsPerSlide : 0
}

const move = (direction = 1): void => {
  let step = 0
  if (currentSlide.value !== 0) {
    step = Math.min(
      maxShift,
      currentSlide.value * (offsetWidth + innerGap)
    )

    step -= currentSlide.value === 1 && direction > 0 ? +props.paddingOnScroll : 0
  }
  innerStyles.value = { transform: `translateX(${-1 * step}px)` }
}

let transitioning = false
const slideTo = (idx: number): void => {
  const dir = idx - currentSlide.value
  if (dir > 0 && !isAllowedRight.value) { return }
  if (dir < 0 && !isAllowedLeft.value) { return }

  if (transitioning || idx === currentSlide.value) { return }
  transitioning = true

  currentSlide.value =
    props.loop && idx >= groupsCount.value
      ? 0
      : props.loop && idx === -1
        ? groupsCount.value - 1
        : idx

  move(dir)

  emit('change:slide', currentSlide.value)

  transitionTimer = setTimeout(() => {
    transitioning = false
  }, props.transition)
}

const isAllowedRight = computed(() => {
  return (props.loop || currentSlide.value < groupsCount.value - 1) && groupsCount.value > 1
})

const isAllowedLeft = computed(() => {
  return (props.loop || currentSlide.value !== 0) && groupsCount.value > 1
})

let touchStart = 0
let touchEnd = 0
let isMoving = false
const onTouchStart = (event: TouchEvent): void => {
  if (!isFullWidth.value) { return }
  touchStart = event.touches[0].clientX
}
const onTouchMove = (event: TouchEvent): void => {
  if (!isFullWidth.value) { return }
  isMoving = true
  touchEnd = event.touches[0].clientX
}
const onTouchEnd = (): void => {
  if (!isMoving) { return }
  isMoving = false

  emit('change:slide', currentSlide.value)

  transitionTimer = setTimeout(() => {
    transitioning = false
  }, props.transition)

  const shift = touchEnd - touchStart
  if (!isFullWidth.value || Math.abs(shift) < 50) { return }
  slideTo(currentSlide.value + (shift < 0 ? 1 : -1))
}

let startPosX = 0
let mouseDown = false

const onPointerDown = (event: MouseEvent): void => {
  startPosX = event.pageX
  mouseDown = true
}
const onPointerMove = (event: MouseEvent): void => {
  if (mouseDown) {
    const shift = event.pageX - startPosX

    if (Math.abs(shift) > 100) {
      carousel.value?.classList.add('moving')
      slideTo(currentSlide.value + (shift < 0 ? 1 : -1))
    }
  }
}
const onPointerEnd = (): void => {
  mouseDown = false
  timeout = setTimeout(() => carousel.value?.classList.remove('moving'), 0)
}

const recalculateCarousel = (): void => {
  const gap = inner.value ? parseFloat(getComputedStyle(inner.value).gap) : 0
  innerGap = gap || (inner.value?.children[1]
    ? parseFloat(getComputedStyle(inner.value.children[1]).marginLeft)
    : 0)
  maxShift = inner.value ? inner.value?.scrollWidth - offsetWidth : 0
  groupsCount.value =
    inner.value && offsetWidth
      ? Math.ceil(
        inner.value?.scrollWidth / (Math.ceil(offsetWidth) + innerGap)
      )
      : 1

  if (isFirstOpen) {
    slideTo(props.idxToSlide)
    isFirstOpen = false
  } else {
    slideTo(0)
  }
}

setCountPerPage()

watch(() => props.idxToSlide, (value) => {
  slideTo(value)
})

// Infinite loading
watch(() => props.totalLoadedItems, recalculateCarousel)

let keydownListener: any = null
let observer: UseResizeObserverReturn | null = null
onMounted(() => {
  observer = useResizeObserver(inner, (entries) => {
    window.requestAnimationFrame(() => {
      if (!isFullWidth.value && screen.mobile.value) { return }

      setCountPerPage()

      const width = entries[0].contentRect.width

      if (Math.abs(offsetWidth - width) > 1) {
        if (recalculateTimer) {
          clearTimeout(recalculateTimer)
        }

        offsetWidth = width

        recalculateTimer = setTimeout(() => {
          recalculateCarousel()
        }, 100)
      }
    })
  })

  if (props.withKeydown) {
    keydownListener = (event: KeyboardEvent) => {
      if (event.code === 'ArrowRight') {
        slideTo(currentSlide.value + 1)
      } else if (event.code === 'ArrowLeft') {
        slideTo(currentSlide.value - 1)
      }
    }

    window.addEventListener('keydown', keydownListener)
  }
})

onUnmounted(() => {
  if (transitionTimer !== null) {
    clearTimeout(transitionTimer)
  }

  if (recalculateTimer !== null) {
    clearTimeout(recalculateTimer)
  }

  if (timeout !== null) {
    clearTimeout(timeout)
  }

  observer?.stop()

  if (props.withKeydown) {
    window.removeEventListener('keydown', keydownListener)
  }
})
</script>

<style lang="postcss">
.carousel {
  --carousel-gap: var(--spacer-base);
  --carousel-arrow-position: calc(-1 * var(--spacer-base));
  --carousel-item-width: auto;
  --info-description-gradient-color: linear-gradient(
    90deg,
    rgb(255 255 255 / 0%) 0,
    var(--color-white) 85%
  );

  position: relative;
  display: inline-grid;
  grid-template-columns: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100%;

  & > div:first-child {
    display: flex;
    overflow: hidden;
    width: 100%;
    max-height: 100%;
    scrollbar-width: none;
  }

  & a {
    user-select: none;
    -webkit-user-drag: none;
  }

  &.moving {
    cursor: grab;
    user-select: none;

    .carousel__list {
      pointer-events: none;
    }
  }

  &__list {
    display: flex;
    gap: var(--carousel-gap);
    align-items: center;
    justify-content: flex-start;
    min-width: 100%;
    transition-timing-function: ease-in-out;
    transition-duration: calc(v-bind(transition) * 1ms);
    transition-property: transform;

    & > * {
      flex-shrink: 0;
      width: var(--carousel-item-width) !important;
      max-width: 100%;
    }
  }

  & > button {
    position: absolute;
    z-index: 1;
    display: flex;

    &:first-of-type {
      left: var(--carousel-arrow-position);
      transform: rotate(180deg);
    }

    &:last-of-type {
      right: var(--carousel-arrow-position);
    }

    @media (hover: hover) and (--screen-lg) {
      &:hover::before {
        background-image: url("/assets/icons/general.svg#chevron-right-white");
      }
    }
  }

  & .gradient {
    position: absolute;
    width: 48px;
    height: 24px;
    background: var(--info-description-gradient-color);
  }

  & .gradient.gradient-left {
    left: -8px;
    transform: rotate(180deg);
  }

  & .gradient.gradient-right {
    right: -8px;
  }

  & > ul {
    --dot-color: rgb(255 255 255 / 50%);
    --dot-color-active: var(--color-white);

    position: absolute;
    bottom: var(--spacer-2xs);
    z-index: 1;
  }

  &--full-width {
    --carousel-gap: 0;
    --carousel-item-width: 100%;
  }

  &--dots-out > ul {
    display: none;
  }

  @media (--screen-lg) {
    &--equal {
      --carousel-item-width:
        calc(
          (100% - (v-bind(count) - 1) * var(--carousel-gap)) / v-bind(count)
        );
    }
  }

  @media (--screen-xs) {
    --carousel-gap: var(--spacer-3xs);

    &:not(&--full-width) {
      width: calc(100% + 2 * var(--spacer-xs));
      margin: 0 calc(-1 * var(--spacer-xs));

      & > div:first-child {
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      & .carousel__list {
        min-width: unset;
        padding: 0 var(--spacer-xs);
      }
    }

    &--dots-out {
      padding-bottom: var(--spacer-base);

      & > ul {
        --dot-color: var(--color-neutral-400);
        --dot-color-active: var(--color-neutral-900);

        bottom: 0;
        display: flex;
      }
    }

    & > button {
      display: none !important;
    }
  }
}
</style>
