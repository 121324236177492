import type { CountryCodeType } from '@/types/locale'

export type InputMask = 'phone' | 'email' | 'phoneOrEmail'

let currLoginType = ''

const isEmail = (value: string): boolean => (/[a-zа-я@.]/ig).test(value)

const clearPhoneValue = (value: string, prevType?: string): string => value.replace(prevType === 'email' ? /[- ()+]/g : /\D/g, '')

const emailMask = (value: string): string => {
  if (currLoginType === 'phone') {
    currLoginType = 'email'
    return clearPhoneValue(value, 'email')
  }

  return value
}

const phoneMask = (value?: string, countryCode?: string): string => {
  if (!value) { return '' }
  let clearedValue = clearPhoneValue(value)

  if (countryCode === 'ru' && clearedValue.startsWith('8')) {
    clearedValue = clearedValue.slice(1)
  }

  let phoneWithMask = ''
  const countryBias = countryCode === 'ua' ? clearedValue.startsWith('380') ? 3 : 0 : Number('01357'.includes(clearedValue[0]))
  const regionBias = countryCode === 'ua' ? 2 : 3

  if (clearedValue.length > countryBias + regionBias + 5) {
    phoneWithMask = `-${clearedValue.slice(countryBias + regionBias + 5, countryBias + regionBias + 7)}`
  }

  if (clearedValue.length > countryBias + regionBias + 3) {
    phoneWithMask = `-${clearedValue.slice(countryBias + regionBias + 3, countryBias + regionBias + 5)}${phoneWithMask}`
  }

  if (clearedValue.length > countryBias + regionBias) {
    phoneWithMask = `) ${clearedValue.slice(countryBias + regionBias, countryBias + regionBias + 3)}${phoneWithMask}`
  }

  const countryPhoneCode = countryBias === 0 ? countryCode === 'ua' ? 380 : 7 : clearedValue.slice(0, countryBias)
  phoneWithMask = `+${countryPhoneCode} (${clearedValue.slice(countryBias, countryBias + regionBias)}${phoneWithMask}`

  return phoneWithMask
}

const phoneOrEmailMask = (value: string, countryCode: CountryCodeType = 'ru'): string => {
  if (isEmail(value.trim())) { return emailMask(value) }

  currLoginType = 'phone'
  return value.length === 0 || value === '+' ? value : phoneMask(value, countryCode)
}

const maskRules: Record<InputMask, (value: string, countryCode?: CountryCodeType) => string> = {
  phone: phoneMask,
  email: emailMask,
  phoneOrEmail: phoneOrEmailMask
}

const isCopyInsertion = (e: KeyboardEvent): boolean => (e.ctrlKey || e.metaKey) && e.code === 'KeyV'
const isArrowKey = (e: KeyboardEvent): boolean => e.code.includes('Arrow')

const handlePhoneInput = (e: KeyboardEvent): boolean => {
  if (!/^\d$/.test(e.key) && e.code !== 'Backspace' && e.code !== 'Tab' && !isCopyInsertion(e) && !isArrowKey) {
    e.preventDefault()
    return false
  }

  return true
}

export {
  isEmail,
  clearPhoneValue,
  emailMask,
  phoneMask,
  phoneOrEmailMask,
  maskRules,
  isCopyInsertion,
  handlePhoneInput
}
