<template>
  <button
    class="button"
    :class="[
      {
        [`button--${variant}`]: variant,
        [`button--${size}`]: size,
        [`i-${icon}`]: iconPosition === 'left' || iconPosition === 'both',
        loading,
      },
      $attrs.class,
    ]"
    :aria-label="ariaLabel"
    :disabled="disabled"
    :title="title"
    @click="emitClick"
  >
    <slot />
    <i
      v-if="iconPosition === 'right' || iconPosition === 'both'"
      :class="`i-${icon}`"
    />
    <LazyALoader v-if="loading" />
  </button>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { IconName } from '@/utils/icon-types'

const LazyALoader = defineAsyncComponent(() => /* @vite-ignore */ import('@/components/atoms/Loader/ALoader.vue'))

export type ButtonVariant = 'grey' | 'secondary' | 'outline' | 'text' | 'success'

defineComponent({ name: 'AButton', inheritAttrs: false })

defineProps({
  title: { type: String, default: '' },
  icon: { type: String as PropType<IconName>, default: '' },
  iconPosition: {
    type: String as PropType<'left' | 'right' | 'both'>,
    default: ''
  },
  disabled: Boolean,
  size: {
    type: String as PropType<'xs' | 'sm' | 'lg' | 'xl' | undefined>,
    default: undefined
  },
  variant: {
    type: String as PropType<ButtonVariant>,
    default: undefined
  },
  ariaLabel: {
    type: String,
    required: false,
    default: undefined
  },
  loading: Boolean
})

const emit = defineEmits({
  click: (event: MouseEvent) => event instanceof MouseEvent
})

const emitClick = (event: MouseEvent): void => {
  emit('click', event)
}
</script>

<style lang="postcss">
/*
  Default: variant - primary, size - base
*/

.button {
  --button: var(--primary);
  --button-color: var(--primary-content);

  position: relative;
  display: flex;
  gap: var(--spacer-4xs);
  align-items: center;
  justify-content: center;
  height: var(--spacer-3xl);
  padding: 0 var(--spacer-2xs);
  border-radius: var(--border-radius-xs);
  background: var(--button);
  color: var(--button-color);
  white-space: nowrap;
  user-select: none;

  @mixin text-base-bold;
  /* stylelint-disable -- This one is okay.*/
  @media (--screen-lg) {
    &:active {
      --button: var(--primary-pressed-dark);
    }
  }
  @media (hover: hover) and (--screen-lg) {
    &:hover {
      --button: var(--primary-focus-dark);
    }
  }
  & > i {
    height: 1.5rem;
  }

  &.loading {
    color: transparent;

    & > *:not(.loader) {
      visibility: hidden;
    }

    & > .loader {
      --loader-top: 50%;
      --loader-size: var(--spacer-3xs);
      --loader-color: var(--color-white);
      --loader-bg: transparent;
    }
  }

  &--xs {
    height: var(--spacer-xl);

    @mixin text-sm-bold;
  }

  &--sm {
    height: var(--spacer-2xl);

    @mixin text-sm;
  }

  &--lg {
    height: var(--spacer-4xl);

    @mixin text-sm-bold;
  }

  &--xl {
    height: 3.5rem;

    @mixin text-sm-bold;
  }

  &--grey {
    --button: var(--color-neutral-200);
    --button-color: var(--color-neutral-900);

    &:active {
      --button: var(--color-neutral-400);
    }

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        --button: var(--color-neutral-300);
      }
    }
  }

  &--secondary {
    --button: var(--color-red-50);
    --button-color: var(--primary);

    &:active {
      --button: var(--color-red-100);
    }

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        --button: var(--color-red-100);
      }
    }
  }

  &--outline {
    --button: transparent;
    --button-color: var(--primary);

    border: var(--spacer-5xs) solid var(--primary);

    &:active {
      --button: transparent;

      color: var(--primary);
      border-color: var(--primary-focus-middle);
    }
    @media (hover: hover) and (--screen-lg) {
      &:hover {
        --button: transparent;

        color: var(--primary-focus-dark);
        border-color: var(--primary-focus-dark);
      }
    }
  }

  &--text {
    --button: transparent;
    --button-color: var(--color-text-middle);

    padding: 0;

    &:active {
      --button-color: var(--color-neutral-800);
    }

    &[disabled] {
      border: none;
      color: var(--color-neutral-300);
      cursor: not-allowed;
    }

    @mixin text-sm;

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        --button: transparent;
        --button-color: var(--color-neutral-700);
      }
    }
  }

  &--success {
    background: var(--color-green-600);

    & > * {
      color: var(--color-white) !important;
    }
  }

  &:not(&--text) {
    &[disabled] {
      --button: var(--color-neutral-300);
      --button-color: var(--color-text-middle);

      border: none;
      cursor: not-allowed;

      @media (hover: hover) and (--screen-lg) {
        &,
        &:hover,
        &:active {
          outline: 0;
        }
      }
    }
  }
}
</style>
