<template>
  <button
    class="icon-button"
    :class="[
      {
        [`icon-button--${variant}`]: variant,
        [`icon-button--${size}`]: size,
        disabled,
      },
      [ `i-${iconName}` ],
    ]"
    :disabled="disabled"
    @click="emitClick"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { IconName } from '@/utils/icon-types'
import type { IconSize } from '@/utils/icon-types-static'

export type IconButtonVariant = 'primary' | 'secondary' | 'rounded' | undefined

export default defineComponent({
  name: 'AIconButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String as PropType<IconName>,
      required: true
    },
    size: {
      type: String as PropType<IconSize>,
      default: undefined
    },
    variant: {
      type: String as PropType<IconButtonVariant>,
      default: undefined
    }
  },
  emits: { click: (event: MouseEvent) => event instanceof MouseEvent },
  setup (_props, { emit }) {
    const emitClick = (event: MouseEvent): void => {
      emit('click', event)
    }

    return { emitClick }
  }
})
</script>

<style lang="postcss">
/*
  Default: variant - invisible, size - base
*/
.icon-button {
  padding: var(--spacer-3xs);
  border-radius: var(--border-radius-xs);
  font-size: 0;

  &.disabled {
    color: var(--color-neutral-300);
  }

  &:not(.disabled):active {
    color: var(--color-neutral-700);
  }

  &--primary {
    background-color: var(--primary);
    color: var(--primary-content);

    &.disabled {
      background-color: var(--color-neutral-300);
      color: var(--color-neutral-500);
    }

    &:not(.disabled):active {
      background-color: var(--primary-pressed-dark);
      color: var(--primary-content);
    }

    @media (hover: hover) and (--screen-lg) {
      &:not(.disabled):hover {
        background-color: var(--primary-focus-dark);
        color: var(--primary-content);
      }
    }
  }

  &--secondary {
    background-color: var(--color-neutral-200);
    color: var(--color-neutral);

    &.disabled {
      background-color: var(--color-neutral-300);
      color: var(--color-neutral-500);
    }

    &:not(.disabled):active {
      background-color: var(--color-neutral-400);
      color: var(--color-neutral);
    }

    @media (hover: hover) and (--screen-lg) {
      &:not(.disabled):hover {
        background-color: var(--color-neutral-300);
        color: var(--color-neutral);
      }
    }
  }

  &--rounded {
    border-radius: var(--border-radius-full);
    background-color: var(--color-white);
    color: var(--color-neutral);
    box-shadow: var(--shadow-base);

    &.icon-button--lg {
      padding: var(--spacer-3xs);

      &::before {
        width: 2rem;
        height: 2rem;
      }
    }

    &:not(.disabled).disabled {
      background-color: var(--color-white);
      color: var(--color-neutral-400);
    }

    &:active {
      background-color: var(--primary-pressed-dark);
      color: var(--primary-content);
    }

    @media (hover: hover) and (--screen-lg) {
      &:not(.disabled):hover {
        background-color: var(--primary);
        color: var(--primary-content);
      }
    }
  }

  &--lg {
    padding: var(--spacer-2xs);
  }

  &--sm {
    padding: calc(var(--spacer-2xs) * 0.5);
  }

  &--xs,
  &--2xs {
    padding: var(--spacer-4xs);
  }

  &--2xs::before {
    width: 1rem;
    height: 1rem;
  }

  @media (hover: hover) and (--screen-lg) {
    &:not(.disabled):hover {
      color: var(--color-neutral-600);
    }
  }
}
</style>
