import { ref } from 'vue'
import { MIN_LAPTOP_WIDTH } from '@/composables/device'

type UseBodyScroll = {
  disableScroll: () => void
  enableScroll: () => void
}

export const useBodyScroll = (): UseBodyScroll => {
  const scrollY = ref(0)

  const disableScroll: UseBodyScroll['disableScroll'] = (): void => {
    if (typeof document === 'undefined') { return }

    if (scrollY.value === 0) {
      scrollY.value = window.scrollY
    }

    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth

    document.body.style.overflow = 'hidden'
    if (window.innerWidth < MIN_LAPTOP_WIDTH) {
      if (scrollY.value !== 0) {
        document.body.style.top = `-${scrollY.value}px`
      }

      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
      document.body.style.paddingRight = '0'
    }

    document.body.style.paddingRight = `${scrollBarWidth}px`

    const headerSticky = document.querySelector('.m-header-center.is-sticky') as HTMLDivElement
    if (headerSticky) {
      headerSticky.style.right = `${scrollBarWidth}px`
    }
  }

  const enableScroll: UseBodyScroll['enableScroll'] = (): void => {
    if (typeof document === 'undefined') { return }

    if (!scrollY.value) {
      const bodyTopPosition = document.body.style.top?.match(/\d+/)?.[0]

      if (bodyTopPosition) {
        scrollY.value = +bodyTopPosition
      }
    }

    const headerSticky = document.querySelector('.m-header-center.is-sticky') as HTMLDivElement
    if (headerSticky) {
      headerSticky.style.removeProperty('right')
    }

    document.body.style.removeProperty('overflow')
    document.body.style.removeProperty('position')
    document.body.style.removeProperty('top')
    document.body.style.removeProperty('width')
    document.body.style.removeProperty('padding-right')
    window.scrollTo(0, scrollY.value)
    scrollY.value = 0
  }

  return { disableScroll, enableScroll }
}
