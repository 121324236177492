<template>
  <component
    :is="href ? (!prevent ? NuxtLink : 'a') : 'span'"
    class="link"
    no-prefetch
    :class="{
      [`link--${size}`]: size,
      [`link--${color}`]: color,
      underline,
    }"
    :target="blank ? '_blank' : '_self'"
    :href="href"
    :rel="blank ? 'noopener' : href.includes('_ll') ? 'nofollow' : undefined"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'

type LinkSize = 'base' | 'sm' | 'sm-bold'
type LinkColor = 'blue' | 'grey' | 'dark'

const NuxtLink = resolveComponent('NuxtLink')

defineProps({
  href: {
    type: String,
    default: ''
  },
  blank: {
    type: Boolean,
    default: false
  },
  prevent: {
    type: Boolean,
    default: false
  },
  size: {
    type: String as PropType<LinkSize>,
    default: undefined
  },
  color: {
    type: String as PropType<LinkColor>,
    default: undefined
  },
  underline: {
    type: Boolean,
    default: false
  }
})
</script>

<style lang="postcss">
/*
  Default: color - blue, size - base
 */
.link {
  --link-color: var(--color-info-middle);
  --link-border-color: var(--color-blue-100);

  display: inline;
  color: var(--link-color);
  cursor: pointer;
  user-select: none;

  &.underline {
    line-height: calc(1.5rem - 1px);
  }

  &.link--sm {
    &.underline {
      line-height: calc(1.25rem - 1px);
    }

    @mixin text-sm;
  }

  &.link--sm-bold {
    @mixin text-sm-bold;
  }

  &.link--grey {
    --link-color: var(--color-text-middle);

    &.underline {
      border-bottom-color: var(--color-neutral-400);
    }
  }

  &.link--dark {
    --link-color: var(--color-text-dark);

    &.underline {
      border-bottom-color: var(--color-neutral-400);
    }
  }

  &.underline {
    border-bottom: 1px solid var(--link-border-color);

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        border-bottom-color: var(--color-blue-100);
      }
    }
  }

  &:active {
    --link-color: var(--color-blue-800);
  }

  &.link--text {
    cursor: default;
  }

  @media (hover: hover) and (--screen-lg) {
    &:hover {
      --link-color: var(--color-blue-800);
    }
  }
}
</style>
