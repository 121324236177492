<template>
  <component
    :is="href ? 'a' : 'span'"
    :class="`social social_${type} i-social-${type}`"
    :href="href ? createUrl : undefined"
    :target="href ? '_blank' : undefined"
    :rel="href ? 'noopener' : undefined"
  >
    {{ type }}
  </component>
</template>

<script lang="ts" setup>
import { defineComponent, computed } from 'vue'
import type { PropType } from 'vue'
import type { SocialType } from '@/components/atoms/Social/types'

defineComponent({ name: 'ASocial' })

const props = defineProps({
  type: {
    type: String as PropType<SocialType>,
    default: 'phone'
  },
  href: {
    type: String,
    default: undefined
  }
})

const createUrl = computed((): string => {
  if (props.href) {
    return props.href
  }
  return ''
})
</script>

<style lang="postcss">
.social {
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  border-radius: var(--border-radius-full);
  background-color: var(--social-background);
  font-size: 0;
  cursor: pointer;

  &::before {
    width: 1rem;
    height: 1rem;
    margin: var(--spacer-3xs);
  }
}

.social_phone {
  --social-background: var(--primary);
}

.social_whatsapp {
  --social-background: #48c95f;
}

.social_telegram {
  --social-background: #2f89ce;
}

.social_vk {
  --social-background: #2787f5;
}

.social_twitter {
  --social-background: #55ace3;
}

.social_mail {
  --social-background: var(--color-icon-middle);
}

.social_ok {
  --social-background: #ef7d01;
}
</style>
