import { useLocalization } from '@/modules/nuxt-localization/composables'
import { maskRules, clearPhoneValue, isEmail } from '@/utils/input/helpers'

import type { Directive } from 'vue'
import type { InputMask } from '@/utils/input/helpers'
import type { CountryCodeType } from '@/types/locale'

const options = new Map()
const defaultOptions = { prevValue: '', mask: [] }
let locale = 'ru'

const getInputElement = (element: HTMLElement): HTMLInputElement | null => {
  if (element.tagName.toUpperCase() === 'INPUT') {
    return element as HTMLInputElement
  }

  const inputElements = element.getElementsByTagName('input')
  if (inputElements.length === 0) {
    return null
  }

  return inputElements[0]
}

const format = (value: string, mask: InputMask | undefined = undefined): string => {
  if (!mask?.length || !maskRules[mask]) {
    return value
  }

  return maskRules[mask](value, locale as CountryCodeType)
}

const formatWithCursor = (value: string, mask: InputMask, cursorPosition?: number | null): [string, number | null] => {
  const isDigit = (value: string) => /\d/.test(value)

  const formattedValue = format(value, mask)

  if (isEmail(value.trim()) || typeof cursorPosition !== 'number') {
    return [formattedValue, null]
  }

  if (value[cursorPosition - 1] === ')' && isDigit(value[cursorPosition])) {
    return [formattedValue, cursorPosition]
  }

  let lastDigitBeforeCursorIdx = cursorPosition
  let resCursorIdx = 0, currDigitIdx = 0

  while (currDigitIdx !== cursorPosition) {
    if (!isDigit(value[currDigitIdx])) {
      lastDigitBeforeCursorIdx--
    }

    currDigitIdx++
  }

  const isBeforeDigit = !isDigit(value[cursorPosition - 1] ?? '') && isDigit(value[cursorPosition] ?? '')

  if ((clearPhoneValue(value).length !== clearPhoneValue(formattedValue).length && !value.includes('+')) || isBeforeDigit) {
    lastDigitBeforeCursorIdx++
  }

  while (lastDigitBeforeCursorIdx > 0 && resCursorIdx < formattedValue.length) {
    if (isDigit(formattedValue[resCursorIdx])) {
      lastDigitBeforeCursorIdx--
    }

    resCursorIdx++
  }

  resCursorIdx = isBeforeDigit ? resCursorIdx - 1 : resCursorIdx
  return [formattedValue, resCursorIdx]
}

const updateMask = (el: HTMLInputElement, mask: string): void => {
  options.set(el, { ...options.get(el) ?? { ...defaultOptions }, mask })
}

const updateValue = (el: HTMLInputElement, force = false): void => {
  const { prevValue, mask } = options.get(el) ?? { ...defaultOptions }
  if (!mask) { return }

  const value = el.value

  options.set(el, { ...options.get(el) ?? { ...defaultOptions }, prevValue: value, mask })

  if ((force || (value && value !== prevValue))) {
    const [formattedValue, resCursor] = formatWithCursor(value, mask, el.selectionStart)

    el.value = formattedValue

    if (resCursor !== null) {
      el.setSelectionRange(resCursor, resCursor)
    }

    const event = document.createEvent('HTMLEvents')
    event.initEvent('input', true, true)
    el.dispatchEvent(event)
  }
}

export const getVMask = (): Directive<HTMLElement, InputMask | undefined> => {
  const { locale: currentLocale } = useLocalization()
  locale = currentLocale.value

  return {
    created (el: HTMLElement, { value }) {
      if (!value) { return }

      const inputElement = getInputElement(el)
      if (!inputElement) { return }

      updateMask(inputElement, value)
      updateValue(inputElement)
    },
    updated (el: HTMLElement, binding) {
      if (!binding.value) { return }

      const inputElement = getInputElement(el)
      if (!inputElement) { return }

      if (binding.value !== binding.oldValue) {
        updateMask(inputElement, binding.value)
      }

      updateValue(inputElement)
    },
    beforeUnmount (el: HTMLElement) {
      const inputElement = getInputElement(el)
      if (!inputElement) { return }

      options.delete(inputElement)
    },
    getSSRProps () {
      return {}
    }
  }
}
