import type { Ref, ComponentPublicInstance } from 'vue'
import {
  ref,
  onMounted,
  onUnmounted
} from 'vue'

type SwipeModalArgs = {
  isActive: boolean
  close: () => void

  innerElementScrollClass?: string
  innerElementDisabledClass?: string
}

type SwipeModalReturns = {
  swipeElement: Ref<HTMLElement>
  overlayComponent: Ref<ComponentPublicInstance>

  moveElementStart: (event: TouchEvent) => void
  moveElement: (event: TouchEvent) => void
  moveElementEnd: (event: TouchEvent, disableAnimation?: boolean) => void
}

export const useSwipeModal = ({
  isActive,
  innerElementScrollClass,
  innerElementDisabledClass,
  close
}: SwipeModalArgs): SwipeModalReturns => {
  const SWIPE_ANIMATION_MS = 200
  const PERCENT_FOR_SWIPE = 25

  const swipeElement = ref<HTMLElement>()
  const overlayComponent = ref<ComponentPublicInstance>()

  // Начальное расстояние от верха модального окна, до верха страницы
  let defaultTopPositionY = 0

  // moveElementStart
  // Начальные координаты Y взаимодействия с модальным окном
  let touchStartY = 0
  // Стартовое расстояние от модалки до верха страницы
  // const startPositionModalY: number = 0
  // Элемент, который может скроллиться внутри модалки находим по классу
  let scrollElement: HTMLElement | null = null
  // Родительский элемент при клике на который модалка не должна свайпаться
  let disabledElement: HTMLElement | null = null
  // Включение / отключение свайпа
  let enableSwipe = true
  // Таймер отслеживания быстрого свайпа
  let fastSwipeTimer: ReturnType<typeof setTimeout> | null

  // moveElement
  // На какое количество пикселей было сделано изменение свайпом
  let currentSwipeChangePosition = 0

  // moveElementEnd
  // Куда происходит фиксация
  let positionFixed: 'top' | 'bottom' = 'top'

  const moveElementStart = (event: TouchEvent): void => {
    if (!isActive || !swipeElement.value) { return }

    touchStartY = event.touches[0].clientY

    defaultTopPositionY = swipeElement.value.getBoundingClientRect().top

    if (innerElementScrollClass) {
      // @ts-expect-error
      scrollElement = event.target?.closest(`.${innerElementScrollClass}`)
    }

    if (innerElementDisabledClass) {
      // @ts-expect-error
      disabledElement = event.target?.closest(`.${innerElementDisabledClass}`)
    }

    swipeElement.value.style.transition = 'none'

    enableSwipe = true

    fastSwipeTimer = setTimeout(() => {
      fastSwipeTimer = null
    }, 500)
  }

  const moveElement = (event: TouchEvent): void => {
    if (!isActive ||
      !enableSwipe ||
      !!(disabledElement) ||
      !swipeElement.value
    ) { return }

    if (scrollElement) {
      if (scrollElement?.scrollTop !== 0) {
        enableSwipe = false
        return
      }
    }

    const touchCurrentY: number = event.touches[0].clientY
    currentSwipeChangePosition = touchStartY - touchCurrentY
    const compareTransform: number = (0 - currentSwipeChangePosition)

    swipeElement.value.style.transform = `translateY(${Math.round(compareTransform)}px)`

    // Расположение модального окна в момент движения от верха окна
    const positionModalY = swipeElement.value.getBoundingClientRect().top
    // Запрещаем модальному окну через чур улетать вверх
    if (positionModalY <= defaultTopPositionY) {
      swipeElement.value.style.transform = 'translateY(0px)'
    }
  }

  const setPositionFixed = (): void => {
    positionFixed = currentSwipeChangePosition < 0 ? 'bottom' : 'top'
  }

  const moveElementEnd = (_event: TouchEvent, disableAnimation = false): void => {
    if (!swipeElement.value) { return }

    const windowHeight = window.innerHeight
    const swipeElementHeight = swipeElement.value.offsetHeight

    swipeElement.value.style.transition = `transform ${SWIPE_ANIMATION_MS}ms ease-in-out`

    // На какое количество пикселей нужно дотянуть (25%) модальное окно чтобы оно свернулось или развернулось
    // От высоты модального окна
    const changeForSwipePx = Math.round((PERCENT_FOR_SWIPE * swipeElementHeight) / 100)

    if (Math.abs(currentSwipeChangePosition) >= changeForSwipePx) {
      setPositionFixed()
    } else if (fastSwipeTimer) {
      setPositionFixed()
      fastSwipeTimer = null
    }

    if (disableAnimation) {
      close()
      currentSwipeChangePosition = 0
    } else {
      if (positionFixed === 'top') {
        swipeElement.value.style.transform = 'translateY(0px)'
      }

      if (positionFixed === 'bottom') {
        overlayComponent.value?.$el.classList.add('opacity-0')
        swipeElement.value.style.transform = `translateY(${windowHeight}px)`
        setTimeout(() => {
          close()
          swipeElement.value?.removeAttribute('style')
          currentSwipeChangePosition = 0
        }, SWIPE_ANIMATION_MS)
      }
    }

    enableSwipe = true
  }

  onMounted(() => {
    if (swipeElement.value) {
      defaultTopPositionY = swipeElement.value.getBoundingClientRect().top
    }
  })

  onUnmounted(() => {
    if (fastSwipeTimer) {
      clearTimeout(fastSwipeTimer)
    }
  })

  return <SwipeModalReturns>{
    swipeElement,
    overlayComponent,
    moveElementStart,
    moveElement,
    moveElementEnd
  }
}
