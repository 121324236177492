<template>
  <div class="overlay" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'AOverlay' })
</script>

<style lang="postcss">
.overlay {
  position: absolute;
  z-index: var(--z-offcanvas);
  display: block;
  height: 100vh;
  background: var(--color-neutral-900);
  opacity: 0.4;
  transition: opacity 0.3s ease-in-out;
  inset: 0;

  &.opacity-0 {
    opacity: 0;
    pointer-events: none;
  }

  @media (--screen-xs) {
    position: fixed;
  }
}
</style>
